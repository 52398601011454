<template>
    <div>
        <TopPage title="Editar" :options="topOptions" :actions="actions" complement="dados bancários" />

        <div class=" p-4 bg-white border-2 rounded-2xl">
            <div class="pt-2 pb-2">
                <div class="flex">
                    <!-- <img :src="user.image" width="80" /> -->
                    <div>
                        <h2 v-if="userBank.user.name" class="text-l flex">
                            <div class="text-gray-600 font-semibold text-2xl font-display font-light">{{ userBank.user.name }}</div>
                        </h2>
                        <div v-else class="load-wraper w-16">
                            <div class="activity"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full py-4">
                <div class="mt-4 flex space-x-3">
                    <div class="w-1/2">
                        <label class="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">Banco</label>
                        <input class="input-primary" v-model="userBank.bank" />
                    </div>
                    <div class="w-1/2">
                        <label class="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">Agência</label>
                        <input class="input-primary" v-model="userBank.agency" />
                    </div>
                    <div class="w-1/2">
                        <label class="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">Conta</label>
                        <input class="input-primary" v-model="userBank.account" />
                    </div>
                </div>

                <div class="mt-8 flex justify-end">
                    <button
                        @click="updateUserBank"
                        class="inline-block px-6 py-2 border-2 border-gray-300 text-white font-semibold text-xs leading-tight uppercase rounded-full hover:bg-green-500 focus:outline-none focus:ring-0 transition duration-150 ease-in-out bg-green-400"
                    >
                        Salvar
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TopPage from '@/components/TopPage.vue';
import { ApiService } from '@/services/api.service';
import { Toast } from '@/services/toast.service';

export default {
    name: 'Operational',
    components: {
        TopPage
    },
    data() {
        return {
            topOptions: {
                datefilter: false,
                actions: true
            },
            actions: [
                // { name: 'Dados bancários', method: this.editBankData },
                { name: 'Redefinir senha', method: this.resetPassword }
            ],
            userBank: {
                user: { name: null }
            }
            // loading: true
        };
    },
    async beforeRouteEnter(to, from, next) {
        const response = await ApiService.get(`/dashboard/users/${to.params.id}/bank`);

        next(vm => {
            vm.userBank = response.data;
        });
    },
    computed: {
        ...mapGetters('auth', ['loggedIn'])
    },
    methods: {
        async resetPassword() {
            await ApiService.customRequest(
                {
                    url: `/dashboard/users/${this.$route.params.id}/reset`,
                    data: {},
                    method: 'POST',
                    confirmTitle: 'Deseja redefinir a senha do usuário?'
                },
                true
            )
                .then(response => {
                    if (response.status == 200) {
                        Toast.fire({
                            icon: 'success',
                            title: 'Senha redefinida salvas com sucesso'
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async updateUserBank() {
            this.loading = true;
            await ApiService.customRequest(
                {
                    url: `/dashboard/users/${this.$route.params.id}/bank`,
                    method: 'POST',
                    data: { bank: this.userBank.bank, agency: this.userBank.agency, account: this.userBank.account },
                    confirmTitle: 'Atualizar as informaçãoes do usuário?'
                },
                true
            )
                .then(response => {
                    if (response.status == 200) {
                        Toast.fire({
                            icon: 'success',
                            title: 'Alterações salvas com sucesso'
                        });
                    }
                })
                .catch(error => {
                    Toast.fire({
                        icon: 'error',
                        title: error.data?.message
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>

<style>
.load-wraper {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: rgb(211, 211, 211);
    z-index: 44;
    overflow: hidden;
    border-radius: 5px;
}
.activity {
    position: absolute;
    left: -45%;
    height: 100%;
    width: 45%;
    background-image: linear-gradient(to left, rgba(251, 251, 251, 0.05), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.6), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.05));
    background-image: -moz-linear-gradient(to left, rgba(251, 251, 251, 0.05), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.6), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.05));
    background-image: -webkit-linear-gradient(to left, rgba(251, 251, 251, 0.05), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.6), rgba(251, 251, 251, 0.3), rgba(251, 251, 251, 0.05));
    animation: loading 1s infinite;
    z-index: 45;
}

@keyframes loading {
    0% {
        left: -45%;
    }
    100% {
        left: 100%;
    }
}
</style>
